// colors
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@import '~@fontsource-variable/roboto-flex';
@import '~@fontsource/roboto-condensed/700.css';

:root {
    /* COLORS */
    --cookie-consent-banner-colors-primary: #2196f3;
    --cookie-consent-banner-colors-primary-border: #2196f3;
    --cookie-consent-banner-colors-primary-content: #fff;
    --cookie-consent-banner-colors-secondary: transparent;
    --cookie-consent-banner-colors-secondary-border: #fff;
    --cookie-consent-banner-colors-secondary-content: #fff;
    --cookie-consent-banner-colors-background-body: rgba(25, 31, 34, 0.92);
    --cookie-consent-banner-colors-text: #fff;

    /* BORDER-RADIUS */
    --cookie-consent-banner-border-radius-buttons: 2rem;
    --cookie-consent-banner-border-radius-body: 1rem;

    /* SPACINGS */
    --cookie-consent-banner-spacings-container-padding-top: 1rem;
    --cookie-consent-banner-spacings-container-padding-left: 1rem;
    --cookie-consent-banner-spacings-container-padding-bottom: 1rem;
    --cookie-consent-banner-spacings-container-padding-right: 1rem;

    --cookie-consent-banner-spacings-body-padding-top: 0.5rem;
    --cookie-consent-banner-spacings-body-padding-left: 2rem;
    --cookie-consent-banner-spacings-body-padding-bottom: 0;
    --cookie-consent-banner-spacings-body-padding-right: 2rem;

    /* Z-INDEX */
    --cookie-consent-banner-z-index-container: 99;

    /* FONTS */
    --cookie-consent-banner-font-family-headline: inherit;
    --cookie-consent-banner-font-size-headline: 1.5rem;
    --cookie-consent-banner-font-family-body: inherit;
    --cookie-consent-banner-font-size-body: 0.875rem;
  }

// .header-slider {
//   overflow: visible !important;
// }
.header-slider .slick-list  {
  // overflow: visible !important;
  // z-index: 3;
  margin: 0 -50px;
}

.header-slider .slick-slide {
  padding: 800

}

.arrow_box {
	position: relative;
	background: #88b7d5;
	border: 4px solid #c2e1f5;
}
.arrow_box:after, .arrow_box:before {
	left: 100%;
	top: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(136, 183, 213, 0);
	border-left-color: #88b7d5;
	border-width: 30px;
	margin-top: -30px;
}
.arrow_box:before {
	border-color: rgba(194, 225, 245, 0);
	border-left-color: #c2e1f5;
	border-width: 36px;
	margin-top: -36px;
}

.benefits-list li {
  padding-bottom: 0.5em;
  line-height: 165%;
}

.benefits-list {
  padding-left: 1.5em;
}

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
  color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
  z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
  .ps__rail-y {
    &:hover > .ps__thumb-y,
    &:focus > .ps__thumb-y,
    &.ps--clicking .ps__thumb-y {
      background-color: $grey500;
      width: 5px;
    }
  }
  .ps__thumb-y {
    background-color: $grey500;
    border-radius: 6px;
    width: 5px;
    right: 0;
  }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
  &.ps--active-y > .ps__rail-y {
    width: 5px;
    background-color: transparent !important;
    z-index: 999;
    &:hover,
    &.ps--clicking {
      width: 5px;
      background-color: transparent;
    }
  }
  &.ps--scrolling-y > .ps__rail-y,
  &.ps--scrolling-x > .ps__rail-x {
    opacity: 0.4;
    background-color: transparent;
  }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes bounce {
  0%,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}

@keyframes slideY {
  0%,
  50%,
  100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-4px);
  }
  75% {
    transform: translateY(4px);
  }
}

@keyframes slideX {
  0%,
  50%,
  100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}
